import React from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export default function SearchMultipleSelect(props) {
  const {
    name,
    label,
    values,
    setValues,
    error = null,
    options,
    placeholder,
  } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(options[0]);

  const OPTIONS_LIMIT = 20;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <FormControl variant="outlined" {...(error && { error: true })}>
      <Autocomplete
        label={label}
        name={name}
        value={value}
        inputValue={inputValue}
        options={options}
        getOptionLabel={(option) => option.title}
        onChange={(event, newValue) => {
          setValue(newValue);
          setValues({
            ...values,
            [name]: newValue.title,
          });
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            placeholder={placeholder}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
