import React, { useState } from "react";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import ProgressBar from "components/ProgressBar/ProgressBar.js";
import SSDProgressBar from "components/ProgressBar/SSDProgressBar.js";
import CircularProgressBar from "components/ProgressBar/CircularProgressBar.js";
import Drawer from "@material-ui/core/Drawer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
// Icons
import SendIcon from "@material-ui/icons/Send";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// Other components
import Publications from "views/Publications/Publications.js";

function createDropDown(row) {
  const colSpan = row.length;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        {row[row.length - 2].map((simRows, index) => (
          <React.Fragment key={simRows}>
            <Paper width="100%">
              <Box m={2} p={2}>
                <Typography variant="h5">
                  Keyword: {row[row.length - 3][index].replaceAll("_", " ")}
                </Typography>
              </Box>
              <Table>
                <colgroup>
                  <col style={{ width: "2.5%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "60%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "2.5%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Keyword</TableCell>
                    <TableCell>SSD</TableCell>
                    <TableCell>Similarity</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                {createDropdownRow(simRows)}
              </Table>
            </Paper>
          </React.Fragment>
        ))}
      </TableCell>
    </TableRow>
  );
}

function createDropdownRow(simRows) {
  return (
    <TableBody>
      {simRows.map((singleSimRow) => (
        <TableRow key={singleSimRow}>
          <TableCell />
          <TableCell component="th" scope="row">
            {singleSimRow.split("|")[0].replaceAll("_", " ")}
          </TableCell>
          <TableCell>
            <SSDProgressBar data={singleSimRow.split("|")[2]} />
          </TableCell>
          <TableCell>
            <ProgressBar value={singleSimRow.split("|")[1] * 100} />
          </TableCell>
          <TableCell />
        </TableRow>
      ))}
    </TableBody>
  );
}

function createData(row) {
  return [
    row.author,
    row.tot_publications,
    row.avg_score,
    row.avg_relevance,
    row.avg_combined,
    row.keyword,
    row.keyword_similarities,
    [row.author, row.keyword],
  ];
}

export default function KeywordsTable(props) {
  const rows = JSON.parse(props.query_result).map((row) => createData(row));
  console.log(rows);
  const [publicationData, setPublicationData] = useState({
    author: "",
    keyword: [],
  });

  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };
  const handlePublications = (data) => {
    setDrawer(true);
    setPublicationData({
      author: data[0],
      keyword: data[1],
    });
  };

  const columns = [
    {
      name: "Author",
      options: {
        customBodyRender: (value) => {
          return (
            <a
              href={`https://boa.unimib.it/simple-search?query=${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "Total # Publications",
    },
    {
      name: "Avg Score",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Avg Relevance",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Avg Combined",
      options: {
        display: "excluded",
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Author Keywords",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Keyword Similarities",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Publications",
      options: {
        customBodyRender: (values) => {
          return (
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={() => handlePublications(values)}
            >
              Search
            </Button>
          );
        },
      },
    },
  ];

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#FF0000",
            },
          },
        },
        MUIDataTableBodyCell: {},
      },
    },
  });

  const components = {
    ExpandButton: function (props) {
      if (
        rows[props.dataIndex] === undefined ||
        rows[props.dataIndex][rows[props.dataIndex].length - 1].length === 0
      ) {
        return <div style={{ width: "24px" }} />;
      }
      return <ExpandButton {...props} />;
    },
  };

  const options = {
    filter: true,
    filterType: "textField",
    responsive: "vertical",
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "100%",
    selectableRows: false,
    expandableRows: true,
    expandableRowsOnClick: false,
    expandableRowsHeader: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData) => {
      return createDropDown(rowData);
    },
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title={"Keywords Discovery"}
          data={rows}
          columns={columns}
          options={options}
          components={components}
        />
      </MuiThemeProvider>
      <Drawer anchor="bottom" open={drawer} onClose={toggleDrawer(false)}>
        <IconButton onClick={toggleDrawer(false)}>
          {drawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Card>
          <CardBody>
            <Publications data={publicationData} />
          </CardBody>
        </Card>
      </Drawer>
    </React.Fragment>
  );
}
