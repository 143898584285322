import React, { useState } from "react";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import ProgressBar from "components/ProgressBar/ProgressBar.js";
import CircularProgressBar from "components/ProgressBar/CircularProgressBar.js";
import Drawer from "@material-ui/core/Drawer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
// Icons
import SendIcon from "@material-ui/icons/Send";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// Other components
import Publications from "views/Publications/Publications.js";

function createDropDown(row) {
  const colSpan = row.length + 1;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Paper width="100%">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Keyword</TableCell>
                <TableCell>Similarity</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row[row.length - 2].map((simRow) => (
                <TableRow key={simRow}>
                  <TableCell />
                  <TableCell component="th" scope="row">
                    {simRow.split("|")[0].replaceAll("_", " ")}
                  </TableCell>
                  <TableCell>
                    <ProgressBar value={simRow.split("|")[1] * 100} />
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableCell>
    </TableRow>
  );
}

function createData(row) {
  return [
    row.key.replaceAll("_", " "),
    row.starting_author_score,
    row.starting_author_rel,
    row.starting_author_combined,
    row.author.replaceAll("_", " "),
    row.author_score,
    row.author_rel,
    row.author_combined,
    row.keys_sim,
    [row.author, [row.key]],
  ];
}

export default function AuthorsTable(props) {
  const rows = JSON.parse(props.query_result).map((row) => createData(row));
  console.log(rows);

  const [publicationData, setPublicationData] = useState({
    author: "",
    keyword: [],
  });

  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };
  const handlePublications = (data) => {
    setDrawer(true);
    setPublicationData({
      author: data[0],
      keyword: data[1],
    });
  };

  const columns = [
    {
      name: "Key",
    },
    {
      name: "Starting Score",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Starting Rel",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Starting Combined",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Author",
      options: {
        customBodyRender: (value) => {
          return (
            <a
              href={`https://boa.unimib.it/simple-search?query=${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "Author Score",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Author Rel",
      options: {
        customBodyRender: (value) => {
          return <CircularProgressBar value={value} />;
        },
      },
    },
    {
      name: "Author Combined",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Keys Similarity",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Publications",
      options: {
        customBodyRender: (values) => {
          return (
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={() => handlePublications(values)}
            >
              Search
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "textField",
    responsive: "vertical",
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "100%",
    selectableRows: false,
    expandableRows: true,
    expandableRowsOnClick: false,
    expandableRowsHeader: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData) => {
      return createDropDown(rowData);
    },
  };

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: "hidden",
        },
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#FF0000",
            },
          },
        },
        MUIDataTableBodyCell: {},
      },
    },
  });

  const components = {
    ExpandButton: function (props) {
      if (
        rows[props.dataIndex] === undefined ||
        rows[props.dataIndex][rows[props.dataIndex].length - 1].length === 0
      ) {
        return <div style={{ width: "24px" }} />;
      }
      return <ExpandButton {...props} />;
    },
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title={"Author Publication Records"}
          data={rows}
          columns={columns}
          options={options}
          components={components}
        />
      </MuiThemeProvider>
      <Drawer anchor="bottom" open={drawer} onClose={toggleDrawer(false)}>
        <IconButton onClick={toggleDrawer(false)}>
          {drawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Card>
          <CardBody>
            <Publications data={publicationData} />
          </CardBody>
        </Card>
      </Drawer>
    </React.Fragment>
  );
}
