/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import KeywordDiscoveryPage from "views/KeywordsDiscovery/KeywordsDiscovery.js";
import AuthorPublicationPage from "views/AuthorPublication/AuthorPublication.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/keywords",
    name: "Search by Keyword",
    icon: LibraryBooks,
    component: KeywordDiscoveryPage,
    layout: "/admin",
  },
  {
    path: "/author",
    name: "Search by Author",
    icon: Person,
    component: AuthorPublicationPage,
    layout: "/admin",
  },
];

export default dashboardRoutes;
