import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  GEO: {
    backgroundColor: "rgb(76, 114, 176)",
  },
  SECSP: {
    backgroundColor: "rgb(221, 132, 82)",
  },
  MPED: {
    backgroundColor: "rgb(85, 168, 104)",
  },
  IUS: {
    backgroundColor: "rgb(196, 78, 82)",
  },
  FIS: {
    backgroundColor: "rgb(129, 114, 179)",
  },
  MPSI: {
    backgroundColor: "rgb(147, 120, 96)",
  },
  MED: {
    backgroundColor: "rgb(218, 139, 195)",
  },
  INGINF: {
    backgroundColor: "rgb(140, 140, 140)",
  },
  SECSS: {
    backgroundColor: "rgb(204, 185, 116)",
  },
  BIO: {
    backgroundColor: "rgb(100, 181, 205)",
  },
  MAT: {
    backgroundColor: "rgb(76, 114, 176)",
  },
  SPS: {
    backgroundColor: "rgb(221, 132, 82)",
  },
  INF: {
    backgroundColor: "rgb(85, 168, 104)",
  },
  MDEA: {
    backgroundColor: "rgb(196, 78, 82)",
  },
  CHIM: {
    backgroundColor: "rgb(129, 114, 179)",
  },
  LLIN: {
    backgroundColor: "rgb(147, 120, 96)",
  },
  MGGR: {
    backgroundColor: "rgb(218, 139, 195)",
  },
  MFIL: {
    backgroundColor: "rgb(140, 140, 140)",
  },
  MSTO: {
    backgroundColor: "rgb(204, 185, 116)",
  },
  AGR: {
    backgroundColor: "rgb(100, 181, 205)",
  },
  LFILET: {
    backgroundColor: "rgb(76, 114, 176)",
  },
  ICAR: {
    backgroundColor: "rgb(221, 132, 82)",
  },
  LOR: {
    backgroundColor: "rgb(85, 168, 104)",
  },
  INGIND: {
    backgroundColor: "rgb(196, 78, 82)",
  },
}));

function singleProgressBar(ssdPoint) {
  const classes = useStyles();
  let label = ssdPoint.split("@")[0];
  let value = ssdPoint.split("@")[1];
  let bar_class = classes[label.replaceAll("-", "")];
  if (label === "") label = "NULL";
  return <ProgressBar label={label} now={value} className={bar_class} />;
}

export default function SSDProgressBar(props) {
  const classes = useStyles();
  const data = props.data.replaceAll("[", "").replaceAll("]", "").slice(0, -1);
  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Box width="100%" mr={1}>
        <ProgressBar>
          {data.split(";").map((ssdPoint) => singleProgressBar(ssdPoint))}
        </ProgressBar>
      </Box>
    </Box>
  );
}
