import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// Custom component
import { useForm, Form } from "components/Form/useForm.js";
import Controls from "../../components/Controls/Controls";
// Icons
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SendIcon from "@material-ui/icons/Send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalculator,
  faLayerGroup,
  faArrowsAltH,
  faFont,
  faUsers,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const fuzzyList = [
  { id: "ON", title: "Enable topic clustering" },
  { id: "OFF", title: "Disable topic clustering" },
];

const operatorList = [
  { id: "AND", title: "AND" },
  { id: "OR", title: "OR" },
];

const initialFieldValues = {
  keyword: "",
  fuzzy: "OFF",
  radius: 0.8,
  operator: "OR",
  alpha: 0.5,
  limit: 25,
  starting_date: "1900",
};

export default function KeywordsForm(props) {
  const classes = useStyles();

  const [keywordList, setKeywordList] = useState([]);

  useEffect(() => {
    fetch("https://boagraph.unimib.it/query_graph/keywords/")
      .then((res) => res.json())
      .then(
        (result) => {
          let tempKeywordList = [];
          result.forEach(function (value) {
            tempKeywordList.push({
              id: value.description,
              title: value.description,
            });
          });
          setKeywordList(tempKeywordList);
        },
        (error) => {
          setKeywordList(error);
        }
      );
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("keyword" in fieldValues)
    //   temp.keyword = fieldValues.keyword
    //     ? ""
    //     : "Please select one of the above options";
    if ("radius" in fieldValues)
      temp.radius = fieldValues.radius ? "" : "This field is required.";
    if ("alpha" in fieldValues)
      temp.alpha = fieldValues.alpha ? "" : "This field is required.";
    if ("limit" in fieldValues)
      temp.limit = fieldValues.limit ? "" : "This field is required.";
    // if ("starting_date" in fieldValues)
    //   temp.starting_date =
    //     fieldValues.starting_date.value < 2021
    //       ? ""
    //       : "Insert a year before 2021.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFieldValues,
    true,
    validate
  );

  if (initialFieldValues == 0) {
    setValues(initialFieldValues);
  }

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const handleSubmit = (e) => {
    if (validate()) {
      props.setLoading(true);
      console.log(values);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(values),
      };
      fetch(
        "https://boagraph.unimib.it/query_graph/submit_keywords_discovery_query",
        requestOptions
      )
        .then((result) => result.json())
        .then(
          (result) => {
            console.log(result);
            props.setQueryResult(result);
            if (result["query_result"] === "") props.setErrorNotification(true);
            else props.setSuccessNotification(true);
            props.setLoading(false);
          },
          (error) => {
            console.log(error);
            props.setErrorNotification(true);
            props.setLoading(false);
          }
        );
    }
    e.preventDefault();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{ backgroundColor: "#a51e3c" }}>
            <h4 className={classes.cardTitleWhite}>Search by Keywords</h4>
            <p className={classes.cardCategoryWhite}>
              Add one or more keywords to start the search
            </p>
          </CardHeader>
          <Form onSubmit={handleSubmit}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  This query identifies the most prolific authors in the BOA
                  community for a (set of) given keyword(s).
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <Controls.SearchMultipleSelect
                    name="keyword"
                    label="Keywords"
                    values={values}
                    setValues={setValues}
                    error={errors.keyword}
                    options={keywordList}
                    placeholder="Add one or more keywords"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button onClick={toggleDrawer(true)}>Advanced options</Button>
                  <Drawer
                    anchor="bottom"
                    open={drawer}
                    onClose={toggleDrawer(false)}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      {drawer ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faSearch} /> Start typing
                                a keyword. You can add more than one keyword at
                                a time. In that case, remember to select the{" "}
                                <FontAwesomeIcon icon={faCalculator} /> logic
                                operator (AND|OR). Example: keyword
                                &#39;artificial intelligence&#39; OR
                                &#39;machine learning&#39; will return a list of
                                10 researchers that used those keywords within
                                their BOA publications. You can also specify to
                                enlarge the search, including keywords similar
                                to those selected (enabling topic clustering),
                                setting a similarity value (&gt;0.9 is the
                                default value). If you want to focus more on
                                recent publications, just select a start time
                                interval to retrieve only publication from that
                                date.
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faLayerGroup} /> Enabling
                                this function, all the keywords similar to the
                                ones included will be automatically retrieved by
                                Boagraph;
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.RadioGroup
                              name="fuzzy"
                              label="Fuzzy"
                              value={values.fuzzy}
                              items={fuzzyList}
                              onChange={handleInputChange}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faArrowsAltH} /> This a
                                threshold similarity value that ranges from 0 to
                                1. Default is 0.9, which means only keywords
                                with at least 90% similarity with the specified
                                ones will be included. Only works when
                                &#39;enable cluster topic&#39; is enabled);
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.LabelledSlider
                              name="radius"
                              label="Threshold"
                              value={values.radius}
                              values={values}
                              setValue={setValues}
                              min={0}
                              max={1}
                              step={0.05}
                              error={errors.radius}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faCalculator} /> Specify
                                how to aggregate keywords, asking a researcher
                                to have all the keywords in her/his track
                                records (AND) or at least one (OR). Only works
                                when &#39;enable cluster topic&#39; is disabled)
                              </li>
                            </ul>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.RadioGroup
                              name="operator"
                              label="Operator"
                              value={values.operator}
                              items={operatorList}
                              onChange={handleInputChange}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faFont} /> (Alpha) The
                                higher the value, the higher the importance of
                                the score. Conversely, the lower the value of{" "}
                                <FontAwesomeIcon icon={faFont} />, the higher
                                the Author&#39;s relevance;
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.LabelledSlider
                              name="alpha"
                              label="Alpha"
                              value={values.alpha}
                              values={values}
                              setValue={setValues}
                              min={0}
                              max={1}
                              step={0.05}
                              error={errors.alpha}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faUsers} /> Specify the
                                number of researchers to be returned;
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <Controls.Input
                              name="limit"
                              label="Limit"
                              value={values.limit}
                              onChange={handleInputChange}
                              error={errors.limit}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faCalendarDay} /> Specify
                                a starting date, to select only BOA product
                                published since then. Default is 1900.
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <Controls.DatePicker
                              name="starting_date"
                              label="Starting Date"
                              value={values.starting_date}
                              onChange={handleInputChange}
                              error={errors.starting_date}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </Drawer>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Controls.Button
                type="submit"
                text="Search"
                startIcon={<SendIcon />}
              />
            </CardFooter>
          </Form>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
