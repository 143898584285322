import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import SearchSelect from "./SearchSelect";
import SearchMultipleSelect from "./SearchMultipleSelect";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import LabelledSlider from "./LabelledSlider";

const Controls = {
  Input,
  RadioGroup,
  SearchSelect,
  SearchMultipleSelect,
  Select,
  Checkbox,
  DatePicker,
  Button,
  LabelledSlider,
};

export default Controls;
