import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

function createData(row) {
  return [
    row.keywords.map((item) => item.replaceAll("_", " ")).join(", "),
    row.title,
    row.handle,
  ];
}

export default function Publications(props) {
  const author = props.data.author;
  const keyword = props.data.keyword;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch("https://boagraph.unimib.it/query_graph/submit_publications_query", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({ author: author, keyword: keyword }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result["query_result"] === "[]") setRows([]);
          else {
            let queryResult = JSON.parse(result["query_result"]);
            let tempQueryList = [];
            queryResult.forEach(function (value) {
              tempQueryList.push({
                keywords: value.keywords,
                title: value.title,
                handle: value.handle,
              });
            });
            setRows(tempQueryList.map((row) => createData(row)));
          }
        },
        (error) => {
          console.log(error);
          setRows([]);
        }
      );
  }, []);

  const columns = [
    {
      name: "Keywords",
    },
    {
      name: "Title",
    },
    {
      name: "Handle",
      options: {
        customBodyRender: (value) => {
          return (
            <a
              href={`https://boa.unimib.it/handle/${value}`}
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          );
        },
      },
    },
  ];

  const theme = createMuiTheme({
    overrides: {
      MUIDataTableSelectCell: {
        MUIDataTable: {
          root: {},
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#FF0000",
            },
          },
        },
        MUIDataTableBodyCell: {},
      },
    },
  });

  const options = {
    filter: true,
    filterType: "textField",
    responsive: "vertical",
    tableBodyHeight: "100%",
    tableBodyMaxHeight: "100%",
    selectableRows: false,
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        title={"Publications"}
        data={rows}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  );
}
