import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";

import KeywordsForm from "./KeywordsForm.js";
import KeywordsTable from "./KeywordsTable.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function KeywordsDiscovery() {
  const classes = useStyles();
  const [queryResult, setQueryResult] = useState({
    "`query`": "",
    "`query_result`": "",
  });

  const [successNotification, setSuccessNotification] = React.useState(false);
  const [errorNotification, setErrorNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessNotification(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorNotification(false);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <KeywordsForm
        setQueryResult={setQueryResult}
        setSuccessNotification={setSuccessNotification}
        setErrorNotification={setErrorNotification}
        setLoading={setLoading}
      />
      {queryResult["query"] ? (
        queryResult["query_result"] === "" ? (
          <Snackbar
            open={errorNotification}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="warning">
              The search you submitted has no results to display!
            </Alert>
          </Snackbar>
        ) : (
          <React.Fragment>
            <Snackbar
              open={successNotification}
              autoHideDuration={6000}
              onClose={handleSuccessClose}
            >
              <Alert onClose={handleSuccessClose} severity="success">
                Search submitted successfully!
              </Alert>
            </Snackbar>
            <KeywordsTable query_result={queryResult.query_result} />
          </React.Fragment>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
}
