import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// Custom component
import { useForm, Form } from "components/Form/useForm.js";
import Controls from "../../components/Controls/Controls";
// Icons
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SendIcon from "@material-ui/icons/Send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faUsers,
  faArrowsAltH,
} from "@fortawesome/free-solid-svg-icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const initialFieldValues = {
  author: "",
  alpha: 0.5,
  limit: 1,
  threshold: 0.7,
};

export default function KeywordsForm(props) {
  const classes = useStyles();

  const [authorList, setAuthorList] = useState([]);

  useEffect(() => {
    fetch("https://boagraph.unimib.it/query_graph/authors/")
      .then((res) => res.json())
      .then(
        (result) => {
          let tempAuthorList = [];
          result.forEach(function (value) {
            tempAuthorList.push({
              id: value.description,
              title: value.description,
            });
          });
          setAuthorList(tempAuthorList);
        },
        (error) => {
          setAuthorList(error);
        }
      );
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("author" in fieldValues)
      temp.author = fieldValues.author
        ? ""
        : "Please select one of the above options";
    if ("alpha" in fieldValues)
      temp.alpha = fieldValues.alpha ? "" : "This field is required.";
    if ("limit" in fieldValues)
      temp.limit = fieldValues.limit ? "" : "This field is required.";
    if ("threshold" in fieldValues)
      temp.threshold = fieldValues.threshold ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFieldValues,
    true,
    validate
  );

  if (initialFieldValues == 0) {
    setValues(initialFieldValues);
  }

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const handleSubmit = (e) => {
    if (validate()) {
      props.setLoading(true);
      console.log(values);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(values),
      };
      fetch(
        "https://boagraph.unimib.it/query_graph/submit_author_publication_comparison_query",
        requestOptions
      )
        .then((result) => result.json())
        .then(
          (result) => {
            console.log(result);
            props.setQueryResult(result);
            if (result["query_result"] === "") props.setErrorNotification(true);
            else props.setSuccessNotification(true);
            props.setLoading(false);
          },
          (error) => {
            console.log(error);
            props.setErrorNotification(true);
            props.setLoading(false);
          }
        );
    }
    e.preventDefault();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{ backgroundColor: "#a51e3c" }}>
            <h4 className={classes.cardTitleWhite}>Search by Author</h4>
            <p className={classes.cardCategoryWhite}>
              Type the name of an author to start the search
            </p>
          </CardHeader>
          <Form onSubmit={handleSubmit}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  This query identifies all the keywords appearing in a
                  researcher&#39;s BOA track records, identifying potential
                  colleagues working on the same topic.
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <Controls.SearchSelect
                    name="author"
                    label="Author"
                    values={values}
                    setValues={setValues}
                    error={errors.author}
                    options={authorList}
                    placeholder="Search for an Author"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <Button onClick={toggleDrawer(true)}>Advanced options</Button>
                  <Drawer
                    anchor="bottom"
                    open={drawer}
                    onClose={toggleDrawer(false)}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      {drawer ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}></GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faFont} /> The higher the
                                value, the higher the importance of the score.
                                Conversely, the lower the value of{" "}
                                <FontAwesomeIcon icon={faFont} />, the higher
                                the Author&#39;s relevance;
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.LabelledSlider
                              name="alpha"
                              label="Alpha"
                              value={values.alpha}
                              values={values}
                              setValue={setValues}
                              min={0}
                              max={1}
                              step={0.05}
                              error={errors.alpha}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faUsers} /> Specify the
                                number of researchers to be returned;
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.LabelledSlider
                              name="threshold"
                              label="Threshold"
                              value={values.threshold}
                              values={values}
                              setValue={setValues}
                              min={0}
                              max={1}
                              step={0.05}
                              error={errors.threshold}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ul>
                              <li>
                                <FontAwesomeIcon icon={faArrowsAltH} /> This a
                                threshold similarity value that ranges from 0 to
                                1. Default is 0.9, which means only keywords
                                with at least 90% similarity with the specified
                                ones will be included.
                              </li>
                            </ul>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Controls.Input
                              name="limit"
                              label="Limit"
                              value={values.limit}
                              onChange={handleInputChange}
                              error={errors.limit}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </Drawer>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Controls.Button
                type="submit"
                text="Search"
                startIcon={<SendIcon />}
              />
            </CardFooter>
          </Form>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
