import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function LabelledSlider(props) {
  const {
    name,
    label,
    value,
    values,
    setValue,
    min,
    max,
    step,
    error = null,
  } = props;
  const classes = useStyles();

  const marks = [
    {
      value: { min },
      label: "0%",
    },
    {
      value: 0.5,
      label: "50%",
    },
    {
      value: 1,
      label: "100%",
    },
  ];

  const [inputValue, setInputValue] = React.useState(value);

  const handleChange = (event, newValue) => {
    setInputValue(newValue);
    setValue({
      ...values,
      [name]: newValue,
    });
  };

  return (
    <div className={classes.root}>
      <Typography>{label}</Typography>
      <Slider
        marks={marks}
        value={inputValue}
        onChange={handleChange}
        label={label}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        {...(error && { error: true, helperText: error })}
      />
    </div>
  );
}
